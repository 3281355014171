html {
  box-sizing: border-box;
  outline: none;
  overflow: auto;
}

.notranslate {
  unicode-bidi: isolate;
}


*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

h1, 
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}


p {
  margin: 0;
  /* font-family:  Tahoma; */
}

body {
  margin: 0;
  background-color: #fff;
}

.container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 20px 0;
  text-align: left;
  /* font-family:  Tahoma */
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: #fff;
}

.header_logo {
  /* flex: 1; */
  display: flex;
  width: 65vw;
  height: 50vh;
  margin: auto;
  /* padding: 20%; */
  /* padding-left: 2vw;
  padding-right: 2vw; */
}

.error_emoji {
  display: flex;
  height: 200px;
  margin: auto;
}

.header_text_bold {
  margin-bottom: 3px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: bold;
}

.header_text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
}

.heading {
  font-size: 22px;
}

.form_wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}

.form_input {
  margin-bottom: 20px;
}

.form_button {
  width: 100%;
  color: white;
  background-color: #b10491;
}

.ant-spin .ant-spin-dot i {
  
  background-color: #b10491;
}

.registrationCityPicker{
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #ccc
}

.navBar {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 0 0 15px 0;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-content: center;
  height: 50px;
  width: 100vw;
  padding-right: 10%;
  padding-left: 10%;
}

.backBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* box-shadow: 10px 0 7px rgba(0, 0, 0, 0.2); */
  height: 50px;
  width: 100vw;
  margin: auto;
  padding-right: 2%;
  padding-left: 2%;
}

.backBarTitle {
  align-self: center;
  justify-self: center;
}

.navIcon {
  margin: 5px auto 3px auto;
}

body {
  margin: 0;
}

.logo {
  width: auto; /* L'immagine non supererà mai la larghezza del contenitore padre */
  height: auto;
  display: block;
  position: relative;
  margin: 3px 0 3px 40px;
}
.main {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.mainPeople{
  display: flex;
  flex-direction: column;
  /* margin: auto;
  justify-content: center;
  align-items: center; */
}

.badge {
  display: flex;
  flex-direction: row;
  /* background-color: #f8a64b; */

  /* border: 1px solid; */
}
.badgePeculiarity {
  background-color: #f8a64b;
  /* border: 3px solid #f8a64b; */
  padding: 0%;
}
.profileImageSubContainer {
  display: flex;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* border: 5px solid #fff; */
  background-color: white;
}
.profileImage {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: block;
  margin: auto;
}


.popUpWindow {
  position: fixed; /* Fisso rispetto alla finestra del browser */
  top: 50%; /* Centro verticale */
  left: 50%; /* Centro orizzontale */
  transform: translate(-50%, -50%); /* Trasla per centrare */
  z-index: 1000; /* Livello di sovrapposizione alto */
  background-color: white; /* Sfondo della scheda */
  padding: 20px; /* Spaziatura interna */
  box-shadow: 0 2px 10px rgba(73, 1, 80, 0.1); /* Ombra per dare profondità */
  border-radius: 8px; /* Angoli arrotondati */
  width: 85%; /* Larghezza massima */
  max-height: 60%; /* Altezza massima */
  overflow: auto; /* Scorrimento se il contenuto è troppo grande */
  border: 2px solid #b10491;
  display:flex;
  justify-content: center;
  align-content: space-between;
}

.popUpWindow .overlay {
  position: fixed; /* Fisso rispetto alla finestra del browser */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 4, 112, 0.5); /* Sfondo semi-trasparente */
  z-index: 999; /* Livello di sovrapposizione subito sotto il popup */
}

.profileImage:hover {
  opacity: 0.5;
}


.profileImgUpdateContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* background-color: #b1049180; */
  /* border: 5px solid #b10491; */
  background-color: #ddd;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5%;
  padding: 2%;
}

.profileImgUploader {
  width: auto;
  height: auto;
}

.profileImgSaveButton {
  margin: 3%;
  width: 50%;
  align-content: center;
  justify-content: center;
  border-radius: 20px;
  display: flex;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
  margin: 1%;
  padding: 1% 2% 1% 2%;
  border: 1px solid #000;
  border-style: solid;
  background-color: #fff;
  font-weight: bold;
}

.profileImgSaveButton:hover {
  background-color: #f8a64b40;
}
.profileUpdateImageButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.identityInfoContainer {
  flex: 1;
  margin: 1%;
  justify-content: center;
  align-items: center;
  /* border:1px solid */
}

.mainInfoContainer {
  /* flex: 2; */
  justify-content: flex-start;
  margin: 5px;
  /* border: 1px solid black; */
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: baseline;
  margin: 0 0 3% 0;
  /* color: #fff; */
}
.secondaryInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 3% 0;
  /* border: 1px solid */
}

.secondaryInfo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: baseline;
  /* color: #fff; */
  font-size: 8;
  font-weight: bold;
  margin: 0 0 0 0;
}

.metricsContainer {
  flex: 3;
  display: flex;
  flex-direction: row;
  margin: 1%;
  padding: 3%;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  /* border: 1px solid */
}

.metricContainer {
  margin: 2%;
  font-weight: bold;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 20%;
  padding: 2%;
  width: 30%;
}

.section {
  width: 80%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 12px;
  margin: auto;
  margin-bottom: 2%;
  padding: 3%;
  /* border: 1px solid; */
}

.itemsSelectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4%;
}

.itemSelection {
  border-radius: 20px;
  display: flex;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
  margin: 1%;
  padding: 1% 2% 1% 2%;
  border: 1px solid #b10491;
  border-style: solid;
  background-color: #fff;
}

.itemText {
  font-size: 12;
  text-align: center;
  max-width: 90%;
  white-space: nowrap;
  color: #b10491;
  font-weight: bold;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.bioInput {
  width: 75vw;
  height: 5vh;
  border: 1px solid #cccc;
  border-radius: 20px;
  padding: 2%;
}

.bioContainer {
  margin-top: 2%;
}

.bioLengthContainer {
  display: flex;
  font-size: 12px;
  color: #777;
  justify-content: right;
}

.buttonParamContainer {
  display: flex;
  flex-direction: column;
}

.form {
  width: 90vw;
}

.formSection {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  /* width: 90%; */
  margin: 5px 15px;
}
label {
  font-size: 15px;
  /* font-weight: bold; */
  margin-bottom: 5px;
  color: grey;
}


.infoInput {
  /* flex: 4; */
  /* width: 70%; */
  /* margin-left: 15px; */
  font-size: 18px;
}

.changeProfImgCont {
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  margin: 0 10px;
  justify-content: space-around;
  /* border: 1px solid; */
}

.uploadButton {
  border-radius: 20px;
  padding: 10px;
  background-color: #b10491;
  font-weight: bold;
  color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  width: 17vw;
}

.buttonUploadCont {
  padding: 10px;
  margin: 0 10px 0 10px;
  font-weight: bold;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectionMenu {
  border-color: #aaa;
  border-radius: 15px;
  font-size: 17px;
  height: 40px;
  /* margin: 5px 1 5px 0; */
}

.matchNavBar {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  width: 70vw;
}
.matchingSubPageSelectionButton {
  display: flex;
  width: 20vw;
  text-decoration: none;
  color: #777777;
  font-weight: bold;
  border-radius: 20px;
  border: 1px solid #777777;
  box-shadow: 0px 0px 5px #7777772d;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 5%;
}

.matchingSubPageSelectionButton:hover {
  box-shadow: 0px 0px 10px #f8a64b;
}

.matchingSubPageSelectionButtonActive {
  background-color: #f8a64b;
  color: #fff;
  border-color: #f8a64b;
}

.matchMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 95%;
}
.matchSymbolContainer {
  display: flex;
  border-radius: 50%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.matchSymbolContainer:hover {
  box-shadow: 0px 0px 35px #f8a64b;
}
.rotating {
  animation: spin 0.8s infinite linear;
  box-shadow: 0px 0px 35px #f8a64b;
}
.matchSymbol {
  height: 100px;
  width: 100px;
}

.searchFilter{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  color: #b10491;
  border: 2px solid #b10491;
  padding: 8px;
  font-weight: bold;
  font-size: 17px;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none
}

.searchFilter:hover {
  box-shadow: 0px 0px 35px #f8a64b;
}

.clipLoaderContainer {
  height: 80vh;
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customClipLoader {
  display: flex;
  animation: spin 0.8s infinite linear;
  height: 100px;
  width: 100px;
  box-shadow: 0;
  align-items: center;
  justify-content: center;
}

.matchSymbolContainerSmall {
  border-radius: 50%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100px;
  width: 100px;
  position: fixed;
  bottom: 70px;
  right: 5px;
  z-index: 9999;
}

.matchSymbolSmall {
  width: 80px;
  height: 80px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.matchingSection {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin-top: 3%;
}

.matchingProfileImgSub {
  display: flex;
  width: 140px;
  height: 140px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  align-self: center;
  border: 3px solid #f8a64b;
  padding: 0%;
  margin: 2%;
}

.matchingScore {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 50px;
  /* background-color: #b1049180; */
  padding: 2%;
  color: #b10491;
}

.matchingPeopleImg {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin: auto;
  display: block;
}
.matchingPeopleInfo {
  flex: 4;
}

.matchingPeopleMainInfo {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: baseline;
}

.matchingPeopleButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: auto;
  align-self: center;
  width: 15vw;
}

.matchingActionPeopleButton {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 5px;
  box-shadow: 0px 0px 10px #0000002d;
}

.matchingActionPeopleButton:hover {
  opacity: 0.5;
}
.matchingPeopleReview {
  margin-top: 2%;
  margin-left: 2%;
  align-items: center;
}

.groupInvitationGroupBox{

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding:10px;
  border-radius: 20px;
  margin: 10px 0 10px 0;

}

.groupinvitationButton{
  color: #b10491;
  font-weight: bold;
  border: 1px solid #b10491;
}

.groupInvitationSent{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding:8px;
  color:  white;
  font-weight: bold;
  font-size: 13px;
  background-color:  #b10491;
  border-radius: 12px;

}

.groupsPageTitles{
  display: flex;
  justify-content: center;
  align-self: center;
  margin: auto;
}

.groupsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 95vw;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  width: 80vw;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-open {
  overflow: hidden;
}

.groupsViewItemsLabel {
  display: block;
  align-self: center;
  font-weight: bold;
  color: white;
  font-size: 15px;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 5px;
}

.groupsViewItemsTitle {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: baseline;
  width: 85vw;
}

.groupsViewItemsSection {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
  width: 100%;
}
.groupsMembersNumber {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.inputChatSection {
  display: flex;
  color: #777;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}

.groupsCreateNew {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 40px 0 40px 0;
  color: #888888;
  justify-content: center;
  align-items: center;
}

.groupsCreateNewWithSuggested {
  margin-top: 0;
  margin-bottom: 10px;
}
.groupsButtonCreateNew {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #0000002d;
  padding: 2%;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.groupsButtonCreateNew:hover {
  background-color: #ddd;
  color: #555;
}

.groupsCreateFormSection {
  margin-bottom: 5%;
  border: none;
}

.mainChatView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  margin-top: -8px;
  margin-bottom: 50px;
}

.chatHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #e0bb52cc;
  font-size: 22px;
  font-weight: bold;
  height: 10%;
  align-items: center;
  padding-left: 8px;
}

.inputChatSection {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: #e0bb52cc;
  padding: 10px;
  margin: auto 0 0 0;
}

.messageViewer {
  flex: 8;
  height: calc(100vh - 100px); /* Altezza massima per il contenuto scorrevole */
  overflow: auto;
  padding: 10px 10px 20px 10px;
}

.messageContainer {
  display: flex;
  margin-top: 5px;
  word-wrap: break-word; /* Per i browser più vecchi */
  overflow-wrap: break-word;
}

.messageText {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 5px;
  padding: 3px 10px;
  background-color: white;
  width: 40vw;
  box-shadow: 0px 0px 10px #0000002d;
}

.senderName {
  font-size: 13px;
  font-weight: bold;
}

.messageTextArea {
  resize: none; /* Impedisce l'espansione dinamica */
  justify-items: center;
  width: 75vw;
  height: 45px;
  border: 1px solid #cccc;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.messageTextArea::-webkit-scrollbar {
  display: none; /* Nasconde le barre di scorrimento */
}
.messageTime {
  margin: 0 0 0 auto;
  font-size: 12px;
}
.right {
  background-color: #ade8ff;
  margin: 0 0 0 auto;
}
.sendButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: white;
  width: 30px;
  height: 30px;
}

.sendButton:hover {
  opacity: 0.5;
}

.notificationViewer {
  margin-top: 10px;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 5px 0 5px;
}

.notificationTime {
  margin: 0 0 0 auto;
  font-size: 14px;
}

.settingOption {
  margin: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  padding: 5px;
  border-radius: 50px;
}

.settingOption:hover {
  background-color: #00000020;
}

.chatContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  width: 90vw;
}

.chatContainerPhoto {
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1px;
  border: 1px solid #aaa;
  background-color: #fff;
}
.chatImg {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.chatContainerMainInfo {
  margin-left: 10px;
}

.chatContainerLastMessage {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  width: 50vw;
  color: #777777;
  white-space: nowrap; /* Evita che il testo vada a capo */
  overflow: hidden; /* Nasconde il testo che eccede la larghezza del contenitore */
  text-overflow: ellipsis; /* Mostra i puntini di sospensione (...) alla fine del testo tronco */
}
.chatContainerDetails {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.newMessagesCircle {
  /* position: absolute;
  right: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 2px;
  height: 17px;
  width: 17px;
  z-index: 1000;
}


.homePageMain {
  font-family: Tahoma;
  text-align: justify;
}

.homePageHeaderButtons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  width: 250px;
  justify-content: space-between;
  align-items: center;
}

.homePageHeaderButtons .ant-btn {
  width: 100px;
  height: 50px;
  font-size: 18px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.homePageNavBar {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 0 0 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* justify-content: center;
  align-content: center; */
  height: 70px;
  width: 100vw;
  padding-right: 10%;
  padding-left: 10%;
}

.homePageHeroSection {
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  font-size: medium;
  background: linear-gradient(135deg, #b10491, #f8a64b);
  color: white;
  margin-bottom: 30px;
}

.homePageHeroSection h3 {
  font-size: 30px;
  margin-bottom: 30px;
}

.homePageHeroSection p {
  font-size: 18px;
  /* margin-bottom: 30px; */
}

.homePageCTA {
  display: inline-block;
  margin: auto;
}

.homePageCTA .ant-btn {
  display: flex;
  text-align: center;
  background-color: #fc3a3e;
  box-shadow: 0px 0px 13px rgba(255, 255, 255, 0.548);
  border-radius: 20px;
  height: 50px;
  width: 100px;
  color: white;
  font-weight: bold;
  border: none;
  padding: auto;
  padding: 10px;
  font-size: 18px;
  margin: 20px;
}

.homePageCTA .ant-btn:hover {
  background-color: #ff7875;
}

.homePageGeneralSection {
  padding: 40px 20px;
  text-align: justify;
  margin: 10px 10px;
}

.homePageGeneralSection h1 {
  text-align: center;
  font-size: 35px;
}

.homePageSolution h1 {
  margin-bottom: 35px;
}

.homePageListFeatures {
  display: flex;
  flex-direction: row;
}

.homePageListItem {
  background-color: #f3f3eb;
  border-radius: 20px;
  padding: 30px;
  margin: 20px 20px;
}

.homePageListItem h2 {
  text-align: center;
  font-size: 25px;
}

.homePageCTAExtended {
  background: linear-gradient(135deg, #b10491, #f8a64b);
  padding: 50px 30px;
  margin: auto;
}

.homePageCTAExtended p {
  color: white;
  font-weight: bold;
  text-align: center;
}

.homePageJoinButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homePageFooter{

  display: flex;
  flex-direction: row;
  color:white;
  background-color: #000;
  height: 100px;
  /* justify-content: center; */
  align-items: center;
}

.createPostText{

  /* height: 30vh; */
  vertical-align: top;
  margin-bottom: 10px;
  padding-top: 10px ;
  border: none;
  resize: none !important;

}

/*
================

CELLULARI

================
 */

@media (max-width: 768px) {


  .homePageListFeatures {
    display: flex;
    flex-direction: column;
  }

  .homePageListItem {
    margin: 20px auto;
  }

  .homePageNavBar {
    height: 50px;
    width: 100vw;
    padding-right: 5%;
    padding-left: 5%;
  }

  .homePageHeaderButtons {
    width: auto;
    height: auto;
    margin-right: 20px;
  }

  .homePageHeaderButtons .ant-btn {
    width: 70px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    margin-right: 10px;
    padding: 5px;
  }
  

  .navBar {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin: 0 0 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    justify-content: center;
    height: 45px;
    width: 100vw;
  }

  #logo {
    width: auto; /* L'immagine non supererà mai la larghezza del contenitore padre */
    height: 100%;
    display: block;
    margin: 3px 0 3px 10px;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    margin: 0 0 8px 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 45px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  .header_logo {
    height: 150px;
    width: 300px;
  }
  .notificationsCircle {
    /* position: absolute;
    right: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 11px;
    padding: 1px;
    height: 15px;
    width: 15px;
    margin-bottom: -15px;
    margin-left: 10px;
    z-index: 1000;
    position: relative;
  }

  .notAndSettContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
  }

  .main {
    padding-bottom: 8%;
    margin: auto;
    justify-content: center;

  }

  .section {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
  }

  .badgePeculiarity {
    background-color: #f8a64b;
  }
  .badge {
    display: flex;
    flex-direction: column;
  }

  .profileImageSubContainer {
    width: 300px;
    height: 300px;
    /* margin: 3%; */
    /* margin-top: -35vh; */
  }

  .profileImage {
    width: 280px;
    height: 280px;
    margin: 3%;
  }

  .profileImgUpdateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .profileImgUploader {
    width: 300px;
    height: 300px;
    margin: 3%;
    align-self: center;
    /* border: 1px solid */
  }
  .identityInfoContainer {
    justify-content: end;
  }
  .itemText {
    font-size: 11;
  }

  .metricsContainer {
    /* flex-direction: column; */
    align-items: flex-start;
    margin: 0%;
    padding: 0%;
    font-size: small;
    /* border: 1px solid black; */
  }

  .metricContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 1%;
    width: 95%;
    border-radius: 50px;
  }

  .groupsPageTitles{
    display: flex;
    justify-content: left;
    align-self: flex-start;
    margin: 0;
  }

  .bioInput {
    width: 87vw;
  }

  .matchingSection {
    flex-direction: column;
  }
  .matchingPeopleMainSection{

    width: 95vw;
  }
  .matchingProfileImgSub {
    width: 140px;
    height: 140px;
  }

  .matchingPeopleImg {
    height: 130px;
    width: 130px;
  }
  .matchingPeopleMainInfo {
    flex-direction: column;
  }

  .matchingPeopleButtons {
    margin-left: -2%;
    width: 50vw;
    align-self: flex-start;
    margin-top: 2%;
  }

  .matchingButton {
    margin: 2% 10% 0% 0%;
  }

  .matchSymbolSmall {
    height: 50px;
    width: 50px;
  }

  .matchSymbolContainerSmall {
    height: 60px;
    width: 60px;
  }
}

.mainChatView {
  height: 85vh;
}
